<ng-container *transloco="let t">
  <ul class="total-details-list">
    <li class="total-details-list-item">
      <span class="item-title">{{ t('totalDetails.subtotalLabel') }}:</span>
      <span class="item-value">{{
        orderTotals.totalAmount | currencyFormat: { forceDecimals: true }
      }}</span>
    </li>
    @if (orderTotals.totalProductDiscount) {
      <li class="total-details-list-item">
        <span class="item-title">{{ t('totalDetails.totalProductDiscountLabel') }}:</span>
        <span class="item-value"
          >- {{ orderTotals.totalProductDiscount | currencyFormat: { forceDecimals: true } }}</span
        >
      </li>
    }
    @if (orderTotals.couponDiscount) {
      <li class="total-details-list-item">
        <span class="item-title">{{ t('totalDetails.couponDiscountLabel') }}:</span>
        <span class="item-value"
          >- {{ orderTotals.couponDiscount | currencyFormat: { forceDecimals: true } }}</span
        >
      </li>
    }
    @if (orderTotals.deliveryCost) {
      <li class="total-details-list-item">
        <span class="item-title">{{ t('totalDetails.deliveryCostLabel') }}:</span>
        <span class="item-value"
          >+ {{ orderTotals.deliveryCost | currencyFormat: { forceDecimals: true } }}</span
        >
      </li>
    }
    @if (orderTotals.outOfDeliveryZone) {
      <li class="total-details-list-item">
        <span class="item-title">{{ t('totalDetails.deliveryCostLabel') }}:</span>
        <span class="item-value">{{ t('totalDetails.outOfDeliveryZoneValue') }}</span>
      </li>
    }
    @if (orderTotals.paymentMethodExtraCharge) {
      <li class="total-details-list-item">
        <span class="item-title">{{ t('totalDetails.extraChargeLabel') }}:</span>
        <span class="item-value"
          >+
          {{ orderTotals.paymentMethodExtraCharge | currencyFormat: { forceDecimals: true } }}</span
        >
      </li>
    }
    @if (orderTotals.paymentMethodDiscount) {
      <li class="total-details-list-item">
        <span class="item-title">{{ t('totalDetails.discountLabel') }}:</span>
        <span class="item-value"
          >- {{ orderTotals.paymentMethodDiscount | currencyFormat: { forceDecimals: true } }}</span
        >
      </li>
    }
    <!-- Food Bank Donation -->
    @if (orderTotals.foodBankDonationAmount) {
      <li class="total-details-list-item">
        <span class="item-title">Donación Banco de Alimentos:</span>
        <span class="item-value">
          +
          {{ orderTotals.foodBankDonationAmount | currencyFormat: { forceDecimals: true } }}
        </span>
      </li>
    }
    <li class="total-details-list-item total">
      <span class="item-title">{{ t('totalDetails.finalAmount') }}:</span>
      <span class="item-value">{{
        orderTotals.finalAmount | currencyFormat: { forceDecimals: true }
      }}</span>
    </li>
  </ul>
</ng-container>
