import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { calculateCartItemsTotal } from '@pedix-workspace/orders';
import {
  TypesOfShippings,
  CartItem,
  EndOrderDetails,
  ShippingOption,
  PersonalInformation,
  PaymentMethod,
  CouponConfigurationResponse,
  EstablishmentCheckout,
} from '@pedix-workspace/utils';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { EndOrderForm } from '../end-order-form/end-order-form.service';
import { CouponValidationService } from './coupon-validation.service';
import { ShippingCostResponse } from '../../main/delivery.service';
import { EndOrderTotals } from '@pedix-workspace/angular-shared-order';

export type EndOrderTotalsParams = {
  cartItems: CartItem[];
  couponConfigurationSnapshot?: CouponConfigurationResponse;
  shippingCostResponse?: ShippingCostResponse;
  endOrderForm?: EndOrderForm;
  paymentMethod?: PaymentMethod;
};

export type NewEndOrderDetailsParams = {
  cartItems: CartItem[];
  establishmentId: string;
  couponConfigurationSnapshot?: CouponConfigurationResponse;
  paymentMethod?: PaymentMethod;
  shippingOption?: ShippingOption;
  shippingCostResponse?: ShippingCostResponse;
  endOrderForm: EndOrderForm;
  endOrderTotals: EndOrderTotals;
  checkoutData: EstablishmentCheckout;
};

@Injectable({
  providedIn: 'root',
})
export class EndOrderService {
  private readonly PERSONAL_INFORMATION_KEY = 'PERSONAL_INFORMATION';

  private t = inject(TranslocoService);
  private cookieService = inject(CookieService);
  private couponValidationService = inject(CouponValidationService);

  get personalInformationData(): PersonalInformation {
    const personalInformationValue: string =
      this.cookieService.get(this.PERSONAL_INFORMATION_KEY) ||
      localStorage.getItem(this.PERSONAL_INFORMATION_KEY);

    if (personalInformationValue) {
      return JSON.parse(personalInformationValue);
    }
    return this.getEmptyPersonalInformation();
  }

  set storePersonalInformationData(newPersonalInformation: PersonalInformation) {
    let domain: string;

    if (location.href.includes('pedix.local')) {
      domain = '.pedix.local';
    } else if (environment.production) {
      domain = '.pedix.app';
    } else {
      domain = '.pedix.dev';
    }

    const cookieOptions: CookieOptions = {
      domain,
      sameSite: 'Strict',
      path: '/',
      secure: domain !== '.pedix.local',
    };

    const oldPersonalInformation = this.personalInformationData;

    const personalInformation: PersonalInformation = {
      ...newPersonalInformation,
      address: {
        ...newPersonalInformation.address,
        // Address details can be toggled off or might be disabled depending on establishment plan
        // This changes from store to store, so we do this to prevent losing the address details in case they were
        // previously captured in a previous sesion
        street: newPersonalInformation?.address?.street || oldPersonalInformation?.address?.street,
        addressDetails:
          newPersonalInformation?.address?.addressDetails ||
          oldPersonalInformation?.address?.addressDetails,
      },
    };

    const personalInformationString = JSON.stringify(personalInformation);

    this.cookieService.set(this.PERSONAL_INFORMATION_KEY, personalInformationString, cookieOptions);
    localStorage.setItem(this.PERSONAL_INFORMATION_KEY, personalInformationString);
  }

  getEndOrderTotals({
    cartItems,
    endOrderForm,
    couponConfigurationSnapshot,
    paymentMethod,
    shippingCostResponse,
  }: EndOrderTotalsParams): EndOrderTotals {
    const totalAmount = calculateCartItemsTotal(cartItems, {
      usePriceDiscount: false,
    });
    const totalAmountWithDiscount = calculateCartItemsTotal(cartItems, {
      usePriceDiscount: true,
    });
    const totalProductDiscount = totalAmount - totalAmountWithDiscount;

    const deliveryCost = shippingCostResponse?.deliveryCost || 0;

    const couponDiscount = couponConfigurationSnapshot
      ? this.couponValidationService.calculateCouponDiscount(cartItems, couponConfigurationSnapshot)
      : 0;
    const priceModifierAmount = this.calculatePriceModifierAmount({
      paymentMethod,
      totalAmount,
      totalProductDiscount,
      couponDiscount,
      deliveryCost,
    });
    const paymentMethodExtraCharge = priceModifierAmount > 0 ? priceModifierAmount : 0;
    const paymentMethodDiscount = priceModifierAmount < 0 ? Math.abs(priceModifierAmount) : 0;
    const foodBankDonationAmount = endOrderForm?.bankFoodDonationChecked
      ? environment.foodBankDonationAmount
      : 0;

    const finalAmount = this.calculateTotalToBePaid({
      totalAmount,
      totalProductDiscount,
      couponDiscount,
      deliveryCost,
      paymentMethodExtraCharge,
      paymentMethodDiscount,
      foodBankDonationAmount,
    });

    return {
      totalAmount,
      totalAmountWithDiscount,
      totalProductDiscount,
      deliveryCost,
      couponDiscount,
      paymentMethodExtraCharge,
      paymentMethodDiscount,
      finalAmount,
      outOfDeliveryZone: shippingCostResponse?.outOfDeliveryZone || false,
      // Food Bank Donation
      foodBankDonationAmount,
    };
  }

  getPersonalInformationData(
    endOrderForm: EndOrderForm,
    requireAddressDetails: boolean,
  ): PersonalInformation {
    return {
      name: endOrderForm.name,
      phone: endOrderForm.phone,
      address: {
        street: requireAddressDetails ? null : endOrderForm.street,
        addressDetails: requireAddressDetails ? endOrderForm.addressDetails : null,
        references: endOrderForm.references,
        isAFlat: endOrderForm.isAFlat,
        floorAndNumber: endOrderForm.floorAndNumber,
      },
    };
  }

  createEndOrderDetails({
    cartItems,
    establishmentId,
    couponConfigurationSnapshot,
    paymentMethod,
    shippingOption,
    shippingCostResponse,
    endOrderTotals,
    endOrderForm,
    checkoutData,
  }: NewEndOrderDetailsParams): EndOrderDetails {
    const personalInformation = this.getPersonalInformationData(
      endOrderForm,
      checkoutData.requireAddressDetails,
    );

    return {
      created: new Date(),
      updated: new Date(),
      status: 'new',
      sequenceId: 0,
      establishmentId,
      personalInformation,
      cartItems,
      totalAmount: endOrderTotals.totalAmount,
      totalAmountWithDiscount: endOrderTotals.totalAmountWithDiscount,
      totalProductDiscount: endOrderTotals.totalProductDiscount,
      deliveryCost: endOrderTotals.deliveryCost,
      couponDiscount: endOrderTotals.couponDiscount,
      paymentMethodExtraCharge: endOrderTotals.paymentMethodExtraCharge,
      paymentMethodDiscount: endOrderTotals.paymentMethodDiscount,
      finalAmount: endOrderTotals.finalAmount,
      couponConfigurationSnapshot: couponConfigurationSnapshot,
      customFieldDetails: checkoutData.customFields?.map((customField, index) => ({
        field: customField,
        value: endOrderForm?.customFieldDetailValues[index],
      })),
      outOfDeliveryZone: shippingCostResponse?.outOfDeliveryZone || false,
      paymentMethod,
      shippingOption,
      tableNumber: endOrderForm?.tableNumber,
      howMuch: endOrderForm?.howMuch,
      shippingEstimate: shippingCostResponse?.shippingEstimate,
      tags: [],
      schemaVersion: 7,
      // Food Bank Donation
      foodBankDonationAmount: endOrderTotals.foodBankDonationAmount,
    };
  }

  calculatePriceModifierAmount(data: {
    paymentMethod?: PaymentMethod;
    totalAmount: number;
    totalProductDiscount: number;
    couponDiscount?: number;
    deliveryCost?: number;
  }) {
    if (!data.paymentMethod || data.paymentMethod.priceModifier === 'none') {
      return 0;
    }
    const subtotal =
      data.totalAmount -
      data.totalProductDiscount -
      (data.couponDiscount || 0) +
      (data.deliveryCost || 0);

    if (data.paymentMethod.priceModifier === 'extraCharge' && data.paymentMethod.extraCharge) {
      return (subtotal * data.paymentMethod.extraCharge) / 100;
    } else if (data.paymentMethod.priceModifier === 'discount' && data.paymentMethod.discount) {
      return (subtotal * data.paymentMethod.discount) / -100;
    } else {
      return 0;
    }
  }

  calculateTotalToBePaid(
    data: Pick<
      EndOrderDetails,
      | 'totalAmount'
      | 'totalProductDiscount'
      | 'couponDiscount'
      | 'deliveryCost'
      | 'paymentMethodExtraCharge'
      | 'paymentMethodDiscount'
      | 'foodBankDonationAmount'
    >,
  ) {
    const subtotal =
      data.totalAmount -
      data.totalProductDiscount -
      (data.couponDiscount || 0) +
      (data.deliveryCost || 0) +
      (data.paymentMethodExtraCharge || 0) -
      (data.paymentMethodDiscount || 0);

    return subtotal + (data.foodBankDonationAmount || 0);
  }

  getShippingOptions(shippingOptionsAvailables: TypesOfShippings[]) {
    const shippingOptions: ShippingOption[] = [];

    shippingOptionsAvailables.forEach((shippingOptionsAvailable: TypesOfShippings) => {
      switch (shippingOptionsAvailable) {
        case TypesOfShippings.IN_PLACE:
          shippingOptions.push({
            name: this.t.translate('endOrder.shippingOptionInPlace'),
            type: TypesOfShippings.IN_PLACE,
          });
          break;
        case TypesOfShippings.TAKE_AWAY:
          shippingOptions.push({
            name: this.t.translate('endOrder.shippingOptionTakeAway'),
            type: TypesOfShippings.TAKE_AWAY,
          });
          break;
        case TypesOfShippings.DELIVERY:
          shippingOptions.push({
            name: this.t.translate('endOrder.shippingOptionDelivery'),
            type: TypesOfShippings.DELIVERY,
          });
          break;
        default:
          break;
      }
    });

    return shippingOptions;
  }

  getEmptyPersonalInformation(): PersonalInformation {
    return {
      name: '',
      phone: '',
      address: undefined,
    };
  }
}
