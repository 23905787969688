import { Injectable, inject } from '@angular/core';
import format from 'date-fns/format';
import {
  CartItem,
  Category,
  PaymentMethod,
  CouponConfigurationResponse,
  CouponDiscountType,
  EstablishmentCheckout,
  DAY_OF_WEEK_NAMES,
} from '@pedix-workspace/utils';
import { CurrencyFormatPipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { getActivePaymentMethods } from '../../../utils/paymentMethods';
import { AppRequestContextService } from '../../../app-request-context.service';
import { calculateCartItemsTotal } from '@pedix-workspace/orders';

@Injectable({
  providedIn: 'root',
})
export class CouponValidationService {
  private currencyFormatPipe = inject(CurrencyFormatPipe);
  private appRequestContext = inject(AppRequestContextService);

  calculateCouponDiscount(
    cartItems: CartItem[],
    couponConfiguration: CouponConfigurationResponse,
  ): number {
    let cartItemsIncluded = cartItems.slice(0);

    if (!couponConfiguration.categories.includes('ALL')) {
      cartItemsIncluded = cartItemsIncluded.filter(cartItem =>
        (couponConfiguration.categories as string[]).includes(cartItem.category.id),
      );
    }
    if (couponConfiguration.productTags?.length > 0) {
      const productTagIdsMap = (<string[]>couponConfiguration.productTags).reduce(
        (map, productTagId) => Object.assign(map, { [productTagId]: true }),
        {},
      );
      cartItemsIncluded = cartItemsIncluded.filter(cartItem =>
        (cartItem.product.linkedProductTagIds || []).some(
          productTagId => productTagIdsMap[productTagId],
        ),
      );
    }

    const totalAmountOfIncludedProducts = calculateCartItemsTotal(cartItemsIncluded, {
      usePriceDiscount: true,
    });

    if (couponConfiguration.discountType === CouponDiscountType.FIXED) {
      return Math.min(totalAmountOfIncludedProducts, couponConfiguration.discount);
    }
    return (totalAmountOfIncludedProducts * couponConfiguration.discount) / 100;
  }

  getCartItemsIncludedInPromo(cartItems: CartItem[], categoryIds: string[]) {
    return cartItems.filter(
      cartItem => categoryIds.includes('ALL') || categoryIds.includes(cartItem.category.id),
    );
  }

  async validateCouponDiscounts({
    cartItems,
    couponConfiguration,
    paymentMethods,
    selectedPaymentMethod,
  }: {
    cartItems: CartItem[];
    couponConfiguration: CouponConfigurationResponse;
    paymentMethods: PaymentMethod[];
    selectedPaymentMethod: PaymentMethod;
  }): Promise<string[] | true> {
    const categories = this.appRequestContext.categories;
    const productTags = this.appRequestContext.productTags;

    const validationResults: (string | true)[] = [
      this.checkCouponConditionDayOfWeek(couponConfiguration.daysOfWeek),
      this.checkCouponConditionPaymentMethods(
        paymentMethods,
        selectedPaymentMethod,
        couponConfiguration.paymentMethods,
      ),
      this.checkCouponConditionCategories(cartItems, couponConfiguration.categories, categories),
      this.checkCouponConditionProductTags(cartItems, couponConfiguration.productTags, productTags),
      this.checkCouponConditionMinAmount(
        cartItems,
        couponConfiguration.categories,
        categories,
        couponConfiguration.minAmount,
      ),
    ];
    const errors = validationResults.filter(result => result !== true) as string[];

    if (errors.length === 0) {
      return true;
    }
    return errors;
  }

  checkCouponConditionDayOfWeek(daysOfWeek: any[]): string | true {
    const dayOfWeek = Number(format(new Date(), 'i'));

    const valid = daysOfWeek.includes('ALL') || daysOfWeek.includes(dayOfWeek);

    if (valid) {
      return valid;
    }

    return `El cupón sólo aplica los días: ${daysOfWeek
      .map(dow => DAY_OF_WEEK_NAMES[dow])
      .join(', ')}.`;
  }

  checkCouponConditionPaymentMethods(
    checkoutPaymentMethods: EstablishmentCheckout['paymentMethods'],
    paymentMethod: PaymentMethod,
    paymentMethods: any[],
  ): string | true {
    if (!paymentMethod) {
      return true;
    }
    const paymentMethodValue = paymentMethod.type || paymentMethod.name;
    const activePaymentMethods = getActivePaymentMethods(checkoutPaymentMethods);

    const valid = paymentMethods.includes('ALL') || paymentMethods.includes(paymentMethodValue);

    if (valid) {
      return valid;
    }
    const validPaymentMethods = this.getValidPaymentMethods(paymentMethods, activePaymentMethods);
    const validPaymentMethodsList = validPaymentMethods.map(pm => pm.name).join(', ');

    return `El cupón sólo aplica con estos medios de pago: ${validPaymentMethodsList}.`;
  }

  checkCouponConditionCategories(
    cartItems: CartItem[],
    categoryIds: string[],
    categories: Pick<Category, 'id' | 'name'>[],
  ): string | true {
    const valid =
      categoryIds.includes('ALL') ||
      cartItems.some(cartItem => categoryIds.includes(cartItem.category.id));

    if (valid) {
      return valid;
    }
    return `El cupón sólo aplica en productos de estas categorías: ${this.getValidCategoryList(
      categoryIds,
      categories,
    ).join(', ')}.`;
  }

  checkCouponConditionProductTags(
    cartItems: CartItem[],
    productTagIds: string[],
    productTags: { id: string; tag: string }[],
  ): string | true {
    const productTagIdsMap = productTagIds.reduce(
      (map, productTagId) => Object.assign(map, { [productTagId]: true }),
      {},
    );

    if (productTagIds?.length === 0) {
      return true;
    }

    const valid = cartItems.some(cartItem =>
      (cartItem.product.linkedProductTagIds || []).some(
        productTagId => productTagIdsMap[productTagId],
      ),
    );

    if (valid) {
      return valid;
    }
    return `El cupón sólo aplica en productos con alguna de las siguientes etiquetas: ${this.getValidProductTagList(
      productTagIds,
      productTags,
    ).join(', ')}.`;
  }

  checkCouponConditionMinAmount(
    cartItems: CartItem[],
    categoryIds: string[],
    categories: Pick<Category, 'id' | 'name'>[],
    minAmount?: number,
  ): string | true {
    const cartItemsIncludedInPromo = this.getCartItemsIncludedInPromo(cartItems, categoryIds);
    const cartItemsTotalAmount = calculateCartItemsTotal(cartItemsIncludedInPromo, {
      usePriceDiscount: true,
    });
    const allCategoriesIncluded = categoryIds.includes('ALL');

    const valid = minAmount ? cartItemsTotalAmount >= minAmount : true;

    if (valid) {
      return valid;
    }
    const errorMessage = `El cupón sólo aplica en compras superiores a ${this.currencyFormatPipe.transform(
      minAmount,
    )}`;

    if (allCategoriesIncluded) {
      return `${errorMessage}.`;
    }
    return `${errorMessage} (sólo se considera el precio de los productos en estas categorías: ${this.getValidCategoryList(
      categoryIds,
      categories,
    ).join(', ')}).`;
  }

  getValidPaymentMethods(
    paymentMethods: CouponConfigurationResponse['paymentMethods'],
    paymentMethodOptions: PaymentMethod[],
  ): PaymentMethod[] {
    if (paymentMethods.includes('ALL')) {
      return paymentMethodOptions;
    }
    return paymentMethods
      .map(paymentMethodType => {
        const pm = paymentMethodOptions.find(
          paymentMethodOption =>
            paymentMethodType === paymentMethodOption.type ||
            paymentMethodType === paymentMethodOption.name,
        );

        return pm;
      })
      .filter(pm => pm !== undefined);
  }

  getValidCategoryList(categoryIds: string[], categories: Pick<Category, 'id' | 'name'>[]) {
    return categoryIds.map(
      categoryId => categories.find(category => category.id === categoryId).name,
    );
  }

  getValidProductTagList(productTagIds: string[], productTags: { id: string; tag: string }[]) {
    return productTagIds.map(
      productTagId => productTags.find(productTag => productTag.id === productTagId).tag,
    );
  }
}
