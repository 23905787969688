@if (label) {
  <label
    class="ui-label ui-label--{{ labelSize }}"
    [ngClass]="{ 'ui-label--required': isRequired }"
    >{{ label }}</label
  >
}

@if (helper) {
  <p class="ui-helper">
    {{ helper }}
  </p>
}

<div class="radio-options">
  @for (option of options; let index = $index; track option) {
    @let optionValue = valueKey ? option[valueKey] : option;
    @let optionChecked = optionValue === value;
    @let optionDisabled = isDisabled || (option | radioIsDisabled: valueKey : disabledKeys);

    <div
      class="radio-item"
      (click)="!optionDisabled && onOptionSelected(option)"
      [ngClass]="{ 'radio-item--checked': optionChecked }"
    >
      <ng-container
        *ngTemplateOutlet="
          radioItemTemplate || defaultRadioItemTemplate;
          context: {
            $implicit: option,
            option,
            index,
            optionValue,
            optionDisabled,
            optionChecked,
          }
        "
      ></ng-container>
    </div>
  }
</div>

<ng-template
  #defaultRadioItemTemplate
  let-option
  let-index="index"
  let-optionValue="optionValue"
  let-optionDisabled="optionDisabled"
  let-optionChecked="optionChecked"
>
  <div class="ui-input__editor">
    <div class="ui-input__radio">
      <input
        [name]="inputId + '-' + index"
        class="ui-input__radio-input"
        type="radio"
        [checked]="optionChecked"
        [value]="optionValue === value ? 'on' : 'off'"
        [disabled]="optionDisabled"
      />
    </div>
    <label
      [attr.for]="inputId"
      class="ui-input__radio-legend"
      [ngClass]="{ 'ui-input__radio-legend--disabled': isDisabled }"
    >
      <ng-container
        *ngTemplateOutlet="
          itemTemplate || defaultItemTemplate;
          context: { $implicit: option, option: option, index }
        "
      ></ng-container>
    </label>
  </div>
</ng-template>

<ng-template #defaultItemTemplate let-option>{{
  labelKey ? option[labelKey] : option
}}</ng-template>

@if (shouldDisplayErrors) {
  <div class="ui-input__error-wrapper">
    <p class="ui-input__error">{{ getErrorMessage(errorEntries[0]) }}</p>
    <pxw-icon class="ui-input__error-icon" icon="info" size="sm" />
  </div>
}

<ng-content></ng-content>
